<template>
	<div class="page">
		<el-tabs style="height: 100%;" type="card" v-model="byType" @tab-click="typeChange">
			<el-tab-pane label="按时间" name="byTime">
				<Tables ref="xTable1" :tableName="tableName" :tableData="List1" :totalPage="totalPage1" :isLoading="isLoading"
					:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList1" @clearSearch="clearSearch">

					<!--搜索自定义内容-->
					<el-form-item slot="search-item">
						<el-input v-model="searchFormData.key" size="small" clearable placeholder="关键字">
						</el-input>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="searchFormData.group_id" placeholder="请选择场站" clearable size="small">
							<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
						</el-select>
					</el-form-item>					
					<el-form-item slot="search-item">
						<el-input v-model="searchFormData.phone" size="small" clearable placeholder="按手机号">
						</el-input>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-date-picker class="from-item-width" size="small" unlink-panels v-model="searchFormData.day" type="date" value-format="yyyy-MM-dd" placeholder="按评论时间">
						</el-date-picker>
					</el-form-item>
					<!--表格内容-->
					<vxe-table-column slot="table-item" field="txt" title="评价信息" align="center" />
					<vxe-table-column slot="table-item" field="phone" title="手机号" align="center" />
					<vxe-table-column slot="table-item" field="score" title="评分" align="center" width="50px" />
					<vxe-table-column slot="table-item" field="group_name" title="场站" align="center" />
					<vxe-table-column slot="table-item" field="order_no" title="关联订单" align="center" />
					<vxe-table-column slot="table-item" field="tm" title="评分时间" align="center" />
					<vxe-table-column slot="table-item" title="回复内容" align="center">
						<template v-slot="{row}">
							<span :style="{color:row.reply?'':'#BBB'}">{{ row.reply||'暂无' }}</span>
						</template>
					</vxe-table-column>
					<vxe-table-column slot="table-item" title="操作" align="center">
						<template v-slot="{row}">
							<el-button v-if="!row.reply" size="small" @click="openReply(row)">回复</el-button>
						</template>
					</vxe-table-column>
				</Tables>				
			</el-tab-pane>
			<el-tab-pane label="按场站" name="byGroup">
				<Tables ref="xTable2" :tableName="tableName" :tableData="List2" :totalPage="totalPage2" :isLoading="isLoading"
					:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList2" @clearSearch="clearSearch">

					<!--搜索自定义内容-->
					<el-form-item slot="search-item">
						<el-input v-model="searchFormData.name" size="small" clearable placeholder="按站点名称">
						</el-input>
					</el-form-item>
					<el-form-item slot="search-item">
						<el-select v-model="searchFormData.citys" size="small" placeholder="请选择城市" style="width:100%">
							<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>
					<!--表格内容-->
					<vxe-table-column slot="table-item" field="group_name" title="站点名称" align="center" min-width="100px" />
					<vxe-table-column slot="table-item" field="avgScore" title="综合评分" align="center" min-width="100px" />
					<vxe-table-column slot="table-item" field="city" title="城市" align="center" min-width="50px" />
					<vxe-table-column slot="table-item" field="cnt" title="评分次数" align="center" min-width="80px" />
					<vxe-table-column slot="table-item" field="maxTime" title="最后评分时间" align="center" min-width="80px" />
					<vxe-table-column slot="table-item" title="操作" align="center" width="220px">
						<template v-slot="{ row }">
							<el-button size="small" plain @click="detail(row)">查看</el-button>
							<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.activitycommon/edit')">编辑</el-button>
							<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
						</template>
					</vxe-table-column>
				</Tables>				
			</el-tab-pane>
		</el-tabs>


		<!-- 编辑 -->
		<el-dialog title="编辑评分" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="500px">
			<el-form :model="editDialogFormData" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="group_name">
					{{ editDialogFormData.group_name }}
				</el-form-item>
				<el-form-item label="评分值" prop="price">
					<el-input-number type="number" :min="1" :max="1000000" :step="1" :precision="0"
						v-model="editDialogFormData.score" style="width: 168px;" class="input" size="small">
					</el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
		<!-- 评论回复 -->
		<el-dialog title="评论回复" :visible.sync="replyDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="500px">
			<el-form :model="replyDialogFormData" ref="replyForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="group_name">
					{{ replyDialogFormData.group_name }}
				</el-form-item>
				<el-form-item label="评论内容" prop="price">
					{{ replyDialogFormData.txt }}
				</el-form-item>				
				<el-form-item label="评分" prop="price">
					<el-rate v-model="replyDialogFormData.score" disabled></el-rate>
				</el-form-item>
				<el-form-item label="回复" prop="price">
					<el-input type="textarea" v-model="replyDialogFormData.reply"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveReply()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-activitycommon-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '场站评分',
				byType:"byTime",
				isLoading: false,
				searchFormData: {},
				List1: [],
				List2: [],
				totalPage1: 0,
				totalPage2: 0,
				groupList:[], //场站列表
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				// 回复
				replyDialogShow: false,
				replyDialogFormData: {},
			}
		},
		computed: {
		},
		mounted() {
			// setTimeout(()=>{
			// 	let divs = document.getElementsByClassName("el-tabs__content");
			// 	console.log(divs);
			// 	divs[1].style.flex = 1;
			// }, 200);
			//this.typeChange();

			//获取场站
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100,
			}
			this.$api.Device.GetGroupMinList(params).then((res)=>{
				this.groupList = res;
			});
		},
		activated() {
		},
		methods: {
			// 表格列表
			async getList1(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					byType: "byTime",
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.Group.score(params);
				this.List1 = res.data
				this.totalPage1 = res.total
				this.isLoading = false;

				setTimeout(()=>{
					let divs = document.getElementsByClassName("vxe-table--body-wrapper");
					divs[0].style.height = '100%';
					divs[1].style.height = '100%';		
				}, 500);
			},
			async getList2(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					byType: "byGroup",
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.Group.score(params);
				this.List2 = res.data
				this.totalPage2 = res.total
				this.isLoading = false;

				setTimeout(()=>{
					let divs = document.getElementsByClassName("vxe-table--body-wrapper");
					divs[0].style.height = '100%';
					divs[1].style.height = '100%';		
				}, 500);
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			typeChange(e){
				//console.log("typeChange", e);
				if(this.byType == "byTime"){
					this.$refs.xTable1.refreshTable();
				}else{
					this.$refs.xTable2.refreshTable();
				}
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				this.editDialogFormData = {
					group_name:row.group_name,
					group_id: row.group_id,
					score: 0
				};
				let res = await this.$api.Market.Group.scorevalue({
					token: this.$store.state.user.token,
					group_id: row.group_id
				});
				if(res){
					this.editDialogFormData.score = res.score;
				}
				this.editDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						group_id: row.group_id
					}
					await this.$api.Market.Group.scoredel(params);
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.typeChange();
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false;
				this.replyDialogShow = false;
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						let params = {
							token: this.$store.state.user.token,
							group_id: this.editDialogFormData.group_id,
							score: this.editDialogFormData.score,
						}
						await this.$api.Market.Group.scoreedit(params);
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog();
						this.typeChange();
					}
				})
			},
			// 返券记录
			detail(row) {
				this.$router.push({
					name: "market-group-scorelist",
					title: row.group_name,
					query: {group_id:row.group_id},
				});
			},
			// 回复评论
			openReply(row) {
				this.replyDialogShow = true;
				this.replyDialogFormData = row;
			},
			// 回复保存
			async saveReply(){
				let params = {
					token: this.$store.state.user.token,
					group_id: this.replyDialogFormData.group_id,
					order_id: this.replyDialogFormData.order_id,
					reply: this.replyDialogFormData.reply,
				}
				await this.$api.Market.Group.scorereply(params);
				this.$notify({
					title: '成功',
					message: '操作成功',
					type: 'success'
				})
				this.closeDialog();
				this.typeChange();
			}
		}
	}
</script>

<style lang="scss" scoped>
.vxe-table--body-wrapper {
    height: auto !important;
}
.el-tabs__content{
	height: 100%;
}
</style>
